* {
  font-family: 'proxima-nova', sans-serif !important;
  text-rendering: optimizeLegibility;
  box-shadow: none !important;
}
.kuswap-font {
  font-family: 'proxima-nova', sans-serif !important;
}
.fa,
.far,
.fas {
  font-family: 'Font Awesome 5 Free' !important;
}

.fab {
  font-family: 'Font Awesome 5 Brands' !important;
}
iframe {
  display: none !important;
}
body {
  margin: 0;
  padding: 0;
  background-color: #fbf9f6;
}
*::-webkit-scrollbar {
  width: 6px;
}
*::-webkit-scrollbar-track {
  background: #ffffff00;
}
*::-webkit-scrollbar-thumb {
  background-color: #9ca3ac;
  border-radius: 20px;
  border: 3px solid #9ca3ac;
}
.wrap-col {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 450px) {
  .wrap-col {
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }
}
.tooltip-toggle {
  cursor: default;

  * {
    pointer-events: none;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
